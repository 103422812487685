import React from 'react'
import { formatDate } from 'helpers'

class Download extends React.Component {
  render() {
    const filetype = this.props.file.split('.').pop()
    return (
      <a
        href={this.props.file}
        className={`c-download${this.props.featured ? ' is-featured' : ''}`}
      >
        {this.props.featured &&
          <div className="c-download__featured">Featured</div>
        }
        <div className="c-download__title-block">
          <div className="c-download__title">{this.props.title}</div>
        </div>
        <div className="c-download__file-type">{filetype}</div>
        <div className="c-download__date">{formatDate(this.props.date)}</div>
      </a>
    )
  }
}

export default Download